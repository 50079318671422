import React from 'react';
import { apolloClient } from '../apollo/client';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {  prepareForURL } from '../utils/helpers'
import { Environment } from "../enviroment";
import { format } from 'date-fns';


const POSTS_QUERY = gql`
query Posts{
    posts {
      id
      Title
      Text
       created_at
      updated_at
       Category {
        id
        Name
      }
      Image {
        id
        url
        formats
      }
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

class Blog extends React.Component {

    state = { allPosts: [], currentPosts: [], currentPage: null, totalPages: null }

    render() {
        return (
            <ApolloProvider client={apolloClient}>
                <React.Fragment>

                    <div className="text-left iq-breadcrumb-one iq-bg-over black ">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-8 col-md-8 text-left align-self-center">
                                    <nav aria-label="breadcrumb" className="text-left">
                                        <h2 className="title">SkillSorted Blog </h2>
                                        <ol className="breadcrumb main-bg">
                                            <li className="breadcrumb-item"><a href="/home">Home</a></li>
                                            <li className="breadcrumb-item active">Blog</li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-right wow fadeInRight">
                                    <img src="/images/svg/blog.svg" className="img-fluid float-right" alt="banner" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <section>
                        <div className="container">
                                <Query query={POSTS_QUERY} >
                                    {

                                        ({ loading, error, data }) => {

                                            if (loading) return <div className="loader"><div className="loader-inner"><div className="cssload-loader"></div></div></div>;

                                            if (error) console.log(error);

                                            return <div className="row"> {

                                                data && data.posts.map(post => {
                                                    return  <div key={post.id} className="col-md-4 wow fadeInUp">
                                                    <div className="iq-blog-box">
                                                        <div className="iq-blog-image">
                                                            <img src={post.Image && post.Image.length > 0 ? Environment.STRAPI_URL + post.Image[0].url : "images/blog-measonry1.jpg"} alt="Latest News" className="img-responsive" />
                                                        </div>
                                                        <div className="iq-blog-detail">
                                                            <div className="iq-blog-meta">
                                                                <ul className="list-inline">
                                                                    <li className="list-inline-item">  <a href={`/blog-detail/${post.id}/${prepareForURL(post.Title)}`} rel="bookmark"> {format(new Date(post.created_at), 'dd/MM/yyyy')} </a> </li>
                                                                </ul>
                                                            </div>
                                                            <div className="blog-title">
                                                                <h4 className="entry-title"> <a href={`/blog-detail/${post.id}/${prepareForURL(post.Title)}`}> {post.Title} </a> </h4>
                                                            </div>
                                                            <div className="blog-content">
                                                                <p dangerouslySetInnerHTML={{ __html: post.Text.substring(0, 250) + '...' }} />
                                                            </div>
                                                            <div className="blog-button"><a className="button-link" href={`/blog-detail/${post.id}/${prepareForURL(post.Title)}`}>Read More<i className="fa fa-angle-right" aria-hidden="true"></i></a> </div>
                                                        </div>
                                                    </div>
                                                </div>;
                                                })
                                            }
                                            </div>
                                        }
                                    }
                                </Query>
                        </div>
                    </section>
                </React.Fragment>
            </ApolloProvider>
        );
    }
}

export default Blog;