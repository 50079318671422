export const appendScript = (scriptToAppend) => {

    const script = document.createElement("script");
    
    script.src = scriptToAppend;
    
    script.async = true;
    
    document.body.appendChild(script);

}

export function prepareForURL(text) {

    text = text.replaceAll(/[^\w\s]/gi, '-').replaceAll(' ','-');

    return text ; 
}