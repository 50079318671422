import React from 'react';
import { apolloClient } from '../apollo/client';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Environment } from "../enviroment";
import { appendScript } from '../utils/helpers'

const TECH_QUERY = gql`
query Technologies{
    technologies {
      id
      TechnologyName
      ImageTitleSEO
      created_at
      updated_at
      Logo {
        id
        url
        formats
      }
    }
  }`;

class HomeTechnologies extends React.Component {
    render() {
        return (
            <ApolloProvider client={apolloClient}>
                <React.Fragment>

                    <section className="pt-0">
                        <div className="container">
                            <div className="row" id="tech-row" style={{display:'none'}}>
                                <div className="col-md-12">
                                    <Query query={TECH_QUERY} >
                                        {
                                            ({ loading, error, data }) => {
                                                if (loading) return <div id="loading">
                                                    <div id="loading-center">
                                                        <img src="/images/loader.gif" alt="loader" />
                                                    </div>
                                                </div>;

                                                if (error) console.log(error);
                                                
                                                setTimeout(() => {
                                                    appendScript("/js/home.js");
                                                }, 1000);

                                                return <div className="owl-carousel owl-loaded owl-drag"
                                                    data-dots="false"
                                                    data-nav="false"
                                                    data-items="6"
                                                    data-items-laptop="4"
                                                    data-items-tab="3"
                                                    data-items-mobile="2"
                                                    data-items-mobile-sm="1"
                                                    data-autoplay="true"
                                                    data-loop="true"
                                                    data-margin="30"
                                                > {
                                                        data && data.technologies.map(tech => {
                                                            return <div key={tech.id} className="item">
                                                                <div className="clients-box">
                                                                    {tech.Logo !== null && tech.Logo !== undefined &&
                                                                        <img className="img-fluid client-img" title={tech.ImageTitleSEO} alt={tech.ImageTitleSEO} src={Environment.STRAPI_URL + tech.Logo.url} />
                                                                    }
                                                                    <p className="text-center">{tech.TechnologyName}</p>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            }
                                        }
                                    </Query>
                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            </ApolloProvider>);
    }
}

export default HomeTechnologies;