import React from 'react';
import TopNavigation from './top-navigation';



class Header extends React.Component {
    render() {
        return (
            <header className="style-one" id="main-header">
                <div className="container-fluid sub-header">
                    <div className="row">
                        <div className="col-auto">
                            <div className="number-info">
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href="mailto:contact@skillsorted.com"> <i className="fa fa-envelope"></i><span>contact@skillsorted.com</span></a>
                                    </li>
                                    <li className="list-inline-item">
                                        {/* <a href="tel:+923335401303"> <i className="fa fa-phone"></i>+92-333-5401303</a> */}
                                        {/* <a href="skype:ali.raza.soft?chat"><i className="fa fa-skype text-primary"></i> </a> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-auto ml-auto sub-main">
                            <div className="social-icone">
                                <ul className="list-inline">
                                    <li className="d-inline">
                                        <a href="https://www.facebook.com/skill.sorted.3" target="_blank"> <i className="fa fa-facebook-f"></i> </a>
                                    </li>
                                    <li className="d-inline">
                                        <a href="#"> <i className="fa fa-google-plus"></i> </a>
                                    </li>
                                    <li className="d-inline">
                                        <a href="#"> <i className="fa fa-linkedin"></i> </a>
                                    </li>
                                    <li className="d-inline">
                                    <a href="skype:ali.raza.soft?chat"><i className="fa fa-skype text-primary"></i> </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid main-header">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <a className="navbar-brand" href="index"> <img className="img-fluid logo" src="/images/ss_logo.png" alt="Skill Sorted" /> </a>
                                <TopNavigation />
                                {/* <SideNavigation /> */}
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;