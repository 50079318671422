import React from 'react';
import { Link } from "react-router-dom";
import { apolloClient } from '../apollo/client';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

const MENU_QUERY = gql`
query MenuQuery {
    menus {
        id
        Title
        Link
        Page {
            id
            Title
          }
        parent {
            id
            Title
            Link
          }
      }
}`;

class TopNavigation extends React.Component {
    render() {
        //console.log(apolloClient);
        return (
            <ApolloProvider client={apolloClient}>
                <React.Fragment>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><i className="ion-navicon"></i></span> </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="menu-main-menu-container">
                            <ul id="top-menu" className="navbar-nav ml-auto">
                                <Query query={MENU_QUERY}>
                                    {
                                        ({ loading, error, data }) => {
                                            if (loading) return <div className="loader"><div className="loader-inner"><div className="cssload-loader"></div></div></div>;

                                            if (error) console.log(error);

                                            return <React.Fragment> {
                                                data && data.menus.map(menu => {

                                                    // if (!ExcludeLinks.includes(menu.Title.toLowerCase())) 
                                                    // {
                                                        
                                                        let Href = menu.Page?  "/pages/" + menu.Page.id + "/" + menu.Link : "/" + menu.Link;

                                                        return <li className="menu-item" key={menu.id}>
                                                            <a className="isubmenu" href={Href}> {menu.Title} </a>
                                                        </li>;
                                                   // }
                                                })
                                            }
                                            </React.Fragment>;
                                        }
                                    }
                                </Query>
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            </ApolloProvider>
        );
    }
}

export default TopNavigation;