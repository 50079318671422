import React from 'react';
import { apolloClient } from '../apollo/client';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { prepareForURL } from '../utils/helpers'
import { Environment } from "../enviroment";
import { format } from 'date-fns';
import HomeTechnologies from './home-technologies';

const SERVICES_QUERY = gql`
query Services{
    services {
      id
      Title
      Description
       created_at
      updated_at
      Image {
        id
        url
        formats
      }
    }
  }`;

class Services extends React.Component {
    render() {
        return (
            <ApolloProvider client={apolloClient}>
                <React.Fragment>
                    <div className="text-left iq-breadcrumb-one iq-bg-over black ">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-8 col-md-8 text-left align-self-center">
                                    <nav aria-label="breadcrumb" className="text-left">
                                        <h2 className="title">Services</h2>
                                        <ol className="breadcrumb main-bg">
                                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                            <li className="breadcrumb-item active">Services</li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-right wow fadeInRight">
                                    <img src="/images/svg/services.svg" className="img-fluid float-right" alt="Software Development Services, Skill Sorted, SkillSorted" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <section>
                        <div className="container">
                            <Query query={SERVICES_QUERY} >
                                {
                                    ({ loading, error, data }) => {

                                        if (loading) return <div id="loading">
                                            <div id="loading-center">
                                                <img src="/images/loader.gif" alt="loader" />
                                            </div>
                                        </div>;

                                        if (error) console.log(error);

                                        return <div className="row"> {

                                            data && data.services.map(service => {
                                                return <div key={service.id} className="col-lg-4 col-md-6">
                                                    <div className="service-box d-inherit p-4 my-3 wow fadeInUp">
                                                        
                                                        {service.Image !== null && service.Image !== undefined &&
                                                            <img alt={service.Title} src={Environment.STRAPI_URL + service.Image.url} />
                                                        }

                                                        <div className="service-detail">
                                                            <h4 className="mb-2">{service.Title}</h4>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div dangerouslySetInnerHTML={{ __html: service.Description }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                        </div>
                                    }
                                }
                            </Query>
                        </div>
                    </section>
                    <HomeTechnologies />
                </React.Fragment>
            </ApolloProvider>);
    }
}

export default Services;