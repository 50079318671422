import React from 'react';
import { apolloClient } from '../apollo/client';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import './page.css';
import { Environment } from "../enviroment";


class Page extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        const Page_QUERY = gql`
        query Page($id: ID!) {
            page(id: $id) {
                id
                Title
                Text
                Name
                HeaderImage {
                  id
                  url
                  name
                }
                ContentImage {
                    id
                    url
                    name
                  }
            }
          }`;
          

        return (
            
            <React.Fragment>
                <ApolloProvider client={apolloClient}>
                    <Query query={Page_QUERY} variables={{ id: parseInt(this.props.match.params.pageId) }} >
                        {

                            ({ loading, error, data }) => {

                                if (loading) return <div className="loader"><div className="loader-inner"><div className="cssload-loader"></div></div></div>;

                                if (error) console.log(error);

                                console.log(data);

                                return <React.Fragment>
                                    <div className="text-left iq-breadcrumb-one iq-bg-over black ">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 col-md-8 text-left align-self-center">
                                                    <nav aria-label="breadcrumb" className="text-left">
                                                        <h2 className="title">{data.page.Name}</h2>
                                                        <ol className="breadcrumb main-bg">
                                                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                                            <li className="breadcrumb-item active">{data.page.Name}</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                                {data.page.HeaderImage !== null && data.page.HeaderImage !== undefined &&
                                                    <div className="col-lg-4 col-md-4 col-sm-12 text-right wow fadeInRight">
                                                        <img alt="banner" className="img-fluid float-right" src={Environment.STRAPI_URL + data.page.HeaderImage.url} />
                                                    </div>
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <section>
                                        <div className="container">
                                            <div className="row iq-how-it-works">
                                                <div className="iq-mb-30 fadeInUp col-sm-12 col-lg-7 col-md-7 wow align-items-center"  dangerouslySetInnerHTML={{__html:data.page.Text}} />
                                                  
                                                {data.page.ContentImage !== null && data.page.ContentImage !== undefined &&
                                                    <div className="fadeInUp col-sm-12 col-lg-5 col-md-5 wow">
                                                        <img alt={data.page.Name} className="single_image-img attachment-full" src={Environment.STRAPI_URL + data.page.ContentImage[0].url} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </section>
                                     </React.Fragment>;
                            }
                        }
                    </Query>
                </ApolloProvider>
            </React.Fragment>);
    }
}

export default Page;