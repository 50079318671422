import React from 'react';
import HomeTechnologies from './home-technologies';


class Home extends React.Component {
    render() {
        return (<React.Fragment>
            <section className="position-relative">
                <div className="container mt-5">
                    <div className="row">

                        <div className="col-lg-8 wow fadeInUp align-items-center">
                            <div className="title-box wow fadeInUp text-left">
                                {/* <span className="title-design">About us</span> */}
                                {/* <h2>We are #SkillSorted</h2> */}
                                <p>A technology consulting company providing excellent quality at an affordable price. We have track record of providing best quality software services to our globally satisfied clients. We are a modern tech company, with a vibrant team of experienced professionals focused on delivering results for their customers by following best practices, proven processes employing the latest technologies.</p>
                            </div>

                            {/* <div className="text-left">
                                        <ul className="iq-list">
                                            <li> <i className="fa fa-check-square-o"></i> <span>Advance Advisory Team</span> </li>
                                            <li> <i className="fa fa-check-square-o"></i> <span>Professional Consulting Services</span> </li>
                                            <li> <i className="fa fa-check-square-o"></i> <span> 24/7 Support Help Center</span> </li>
                                            <li> <i className="fa fa-check-square-o"></i> <span>Customer Service &amp; Operations</span> </li>
                                        </ul>
                                    </div> 
                            <div className=" text-left mt-5 ">
                                <a className="button_link" href="/about-us"><i className="fas fa fa-play"></i>Read More? About Us</a>
                            </div>*/}
                        </div>
                        <div className="col-lg-4">
                            <img src="/images/svg/web_developer.svg" className="about-box-img" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="iq-pb-85">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pr-3">
                            <div className="title-box wow fadeInUp mb-0 text-center text-lg-left border-right ">
                                <h2>Why Choose <br className="d-none d-lg-block" /> Skill Sorted</h2>
                            </div>
                        </div>
                        <div className="col-lg-8 align-self-center">
                            <p>We have managed to build the best software engineers' team and attracted the best talent from the software industry to give you access to a pool of talented resources for all the phases of your software development life cycle.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-0">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-sm-12">
                    <div className="SS-services  mb-0 wow fadeInUp text-center">
                    <div className="services-detail">
                        <img src="images/svg/quality.svg"  style={{width:'30%'}} alt="best quality software, skill sorted, skillsorted" />
                        <h5 className="mb-1">Best Quality in Affoardable Price</h5>
                        <p className="mb-0">We have track record of providing best quality software services to our globally satisfied clients.</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                    <div className="SS-services mb-0 wow fadeInUp text-center">
                    <div className="services-detail">
                        <img  src="images/svg/team.svg"  style={{width:'30%'}}  alt="teamwork, team work, skill sorted, skillsorted" />
                        <h5 className="mb-1">Vibrant Team of Professionals </h5>
                        <p className="mb-0">All our teams have gone through the experience of delivering multiple projects to range of industries.</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                    <div className="SS-services mb-0 wow fadeInUp text-center">
                    <div className="services-detail">
                        <img src="images/svg/technology.svg"  style={{width:'30%'}}  alt="latest technology, skill sorted, skillsorted" />
                        <h5 className="mb-1">Latest Technology Stack</h5>
                        <p className="mb-0">Choosing the right technology stack for your software product is crucial for your business success so we always choose the best for you. </p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-12">
                    <div className="SS-services mb-0 wow fadeInUp text-center">
                        <div className="services-detail">
                            <img  src="images/svg/fast.svg"  style={{width:'30%'}}  alt="faster, reliable, cheaper software, skill sorted, skillsorted" />
                            <h5 className="mb-1">Faster, Cheaper and Reliable</h5>
                            <p className="mb-0">We deliver faster, cheaper and reliable than contractors, freelancers or full time in house software development teams.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <HomeTechnologies />
        </React.Fragment>);
    }

} 

export default Home;