// export const Environment = 
// { 
//     GRAPHQL_URL : 'http://localhost:1338/graphql',
//     STRAPI_URL :  'http://localhost:1338'
// };

export const Environment = 
{ 
    GRAPHQL_URL : 'https://api.skillsorted.com/graphql',
    STRAPI_URL :  'https://api.skillsorted.com'
};