import React from 'react';
import { apolloClient } from '../apollo/client';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import './page.css';
import { format } from 'date-fns';
import { Environment } from "../enviroment";


class Page extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        const POST_QUERY = gql`
        query Post($id: ID!) {
            post(id: $id) {
                id
                Title
                Text
                 created_at
                updated_at
                 Category {
                  id
                  Name
                }
                Image {
                  id
                  url
                  formats
                }
            }
          }`;

        return (
            <React.Fragment>
                <ApolloProvider client={apolloClient}>
                    <Query query={POST_QUERY} variables={{ id: parseInt(this.props.match.params.postId) }} >
                        {

                            ({ loading, error, data }) => {

                                if (loading) return <div className="loader"><div className="loader-inner"><div className="cssload-loader"></div></div></div>;

                                if (error) console.log(error);

                                return <React.Fragment>
                                    <div className="text-left iq-breadcrumb-one iq-bg-over black">
                                        <div className="container">
                                            <div className="row align-items-center">
                                                <div className="col-lg-8 col-md-8 text-left align-self-center">
                                                    <nav aria-label="breadcrumb" className="text-left">
                                                        <h2 className="title">{data.post.Title}</h2>
                                                        <ol className="breadcrumb main-bg">
                                                            <li className="breadcrumb-item"><a href="/index">Home</a></li>
                                                            <li className="breadcrumb-item"><a href="/Blog">Blog</a></li>
                                                            <li className="breadcrumb-item active">{data.post.Title}</li>
                                                        </ol>
                                                    </nav>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12 text-right wow fadeInRight">
                                                    <img src="/images/about-us/04.png" className="img-fluid float-right" alt="banner" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <section>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-8 col-sm-12">
                                                    <div className="iq-blog-box">
                                                        <div className="iq-blog-image wow fadeInUp">

                                                            <img src={data.post.Image && data.post.Image.length > 0 ? Environment.STRAPI_URL + data.post.Image[0].url : "/images/blog-measonry1.jpg"} alt="Latest News" className="img-responsive attachment-post-thumbnail size-post-thumbnail wp-post-image" />
                                                        </div>
                                                        <div className="iq-blog-detail">
                                                            <div className="iq-blog-meta wow fadeInUp">
                                                                <ul className="list-inline">
                                                                    <li className="list-inline-item">
                                                                        <a href="#!" rel="bookmark">
                                                                            {format(new Date(data.post.created_at), 'dd/MM/yyyy')}
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="blog-content">
                                                                <div className="blog-content wow fadeInUp">
                                                                    <div dangerouslySetInnerHTML={{ __html: data.post.Text }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-sm-12 sidebar-service-right mt-5 mt-lg-0">
                                                    <div id="secondary" className="widget-area" >
                                                        <div className="testimonial-widget-menu widget wow fadeInRight">
                                                            <div className="owl-carousel" data-dots="true" data-nav="false" data-autoplay="true" data-loop="true" data-items="1" data-items-laptop="1" data-items-tab="1" data-items-mobile="1" data-items-mobile-sm="1" data-margin="30">
                                                                <div className="testimonial-info">
                                                                    <p>Richard McClintock, a Latin professor at Hampden-Sydney consectetur adip isicing elit, sed dolore magna aliqua hampden. Latin professor at Hampden-Sydney consectetur.</p>
                                                                    <div className="post-img">
                                                                        <img width="150" height="150" src="/images/testimonials/1.jpg" className="attachment-thumbnail size-thumbnail wp-post-image" alt="" />
                                                                        <div className="testimonial-name">
                                                                            <h5>Jack White</h5>
                                                                            <span className="sub-title">CEO</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="testimonial-info">
                                                                    <p>&#8220;Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium incididunt ut labore et dolore Sed ut perspiciatis error. Perspiciatis unde omnis iste.&#8221;</p>
                                                                    <div className="post-img">
                                                                        <img width="150" height="150" src="/images/testimonials/2.jpg" className="attachment-thumbnail size-thumbnail wp-post-image" alt="" />
                                                                        <div className="testimonial-name">
                                                                            <h5>Walhan Bobe</h5>
                                                                            <span className="sub-title">Business Advisor</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="testimonial-info">
                                                                    <p>“Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.”</p>
                                                                    <div className="post-img">
                                                                        <img width="150" height="150" src="/images/testimonials/3.jpg" className="attachment-thumbnail size-thumbnail wp-post-image" alt="" />
                                                                        <div className="testimonial-name">
                                                                            <h5>Mark McManus</h5>
                                                                            <span className="sub-title">COO</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="iq-widget-menu widget wow fadeInRight">
                                                            <h2 className="widget-title">Recent Post</h2>
                                                            <div className="list-inline iq-widget-menu">
                                                                <ul className="iq-post">
                                                                    <li>
                                                                        <div className="post-img">
                                                                            <img width="150" height="150" src="/images/about-us/01.png" alt="" />
                                                                            <div className="post-blog">
                                                                                <div className="blog-box">
                                                                                    <ul className="list-inline">
                                                                                        <li className="list-inline-item  mr-3"><a href="blog-details.html"> <i className="fa fa-calendar mr-2" aria-hidden="true"></i>September 26, 2019</a> </li>
                                                                                    </ul>
                                                                                    <a className="new-link" href="blog-details.html">
                                                                                        <h5>Construction industry</h5>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="post-img">
                                                                            <img width="150" height="150" src="/images/about-us/02.png" className="attachment-thumbnail size-thumbnail wp-post-image" alt="" />
                                                                            <div className="post-blog">
                                                                                <div className="blog-box">
                                                                                    <ul className="list-inline">
                                                                                        <li className="list-inline-item  mr-3"><a href="blog-details.html"><i className="fa fa-calendar mr-2" aria-hidden="true"></i>September 26, 2019</a> </li>
                                                                                    </ul>
                                                                                    <a className="new-link" href="blog-details.html">
                                                                                        <h5> Build Construction</h5>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <div className="post-img">
                                                                            <img width="150" height="150" src="/images/about-us/03.png" className="attachment-thumbnail size-thumbnail wp-post-image" alt="" />
                                                                            <div className="post-blog">
                                                                                <div className="blog-box">
                                                                                    <ul className="list-inline">
                                                                                        <li className="list-inline-item  mr-3"><a href="blog-details.html"><i className="fa fa-calendar mr-2" aria-hidden="true"></i>September 26, 2019</a> </li>
                                                                                    </ul>
                                                                                    <a className="new-link" href="blog-details.html">
                                                                                        <h5> Life Lack Meaning</h5>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div id="categories-4" className="widget widget_categories wow fadeInRight">
                                                            <h2 className="widget-title"> Categories</h2>
                                                            <ul>
                                                                <li className="cat-item cat-item-3"> <a href="#">CSS</a> <span className="post_count"> 2 </span> </li>
                                                                <li className="cat-item cat-item-2"> <a href="#">Design</a> <span className="post_count"> 2 </span> </li>
                                                                <li className="cat-item cat-item-4"> <a href="#">HTML</a> <span className="post_count"> 2 </span> </li>
                                                                <li className="cat-item cat-item-1"> <a href="#">Uncategorized</a> <span className="post_count"> 1 </span> </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </React.Fragment>;
                            }
                        }
                    </Query>
                </ApolloProvider>
            </React.Fragment>);
    }
}

export default Page;