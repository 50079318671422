import React from 'react';
import { apolloClient } from '../apollo/client';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { appendScript, prepareForURL } from '../utils/helpers'
import { Environment } from "../enviroment";
import { format } from 'date-fns';
import ReCAPTCHA from "react-google-recaptcha"
import { Textbox, Textarea } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';

class ContactUs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formSubmitted: false,
            name: "",
            email: "",
            message: "",
            subject: "",
            captchaValue: "",
            isLoading: false,
            hasNameError: true,
            hasEmailError: true,
            hasMessageError: true,
            hasSubjectError: true,
            validate: false,
            errorMessage: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }


    onChange(value) {
        this.setState({ captchaValue: value });
        console.log("Captcha value:", value)
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }


    async handleSubmit(e) {

        e.preventDefault();
        this.toggleValidating(true);

        const
            {
                name,
                email,
                message,
                subject,
                captchaValue,
                hasNameError,
                hasEmailError,
                hasSubjectError,
                hasMessageError
            } = this.state;

        if (!hasNameError && !hasEmailError && !hasMessageError && !hasSubjectError) {

            const CONTACTUS_MUTATION = gql`mutation SendContactUsEmail($Message:String!){
                createContactUs(input:{ data:{Captcha:"${captchaValue}", Subject:"MTM Web Contact Us Email", Name:"${name}", EmailAddress:"${email}", Message:$Message}}){
                  contactUs{
                    id,
                    EmailAddress,
                    Name,
                    Message
                  }
                }
              }`;

            this.setState({
                isLoading: true
            });

            try {
                const result = await apolloClient.mutate({ mutation: CONTACTUS_MUTATION, variables: { Message: message } });

                if (result) {
                    this.setState({
                        isLoading: false,
                        formSubmitted: true
                    });
                }

            } catch (ex) {

                this.setState({
                    isLoading: false,
                    errorMessage: `Error! ${ex}`
                });

            }
        }

        return false;
    }

    render() {
        const {
            name,
            email,
            message,
            subject,
            validate
        } = this.state;

        return (
            <React.Fragment>
                {
                    this.state.isLoading && <div id="loading">
                        <div id="loading-center">
                            <img src="/images/loader.gif" alt="loader" />
                        </div>
                    </div>
                }

                <div className="text-left iq-breadcrumb-one iq-bg-over black ">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-8 col-md-8 text-left align-self-center">
                                <nav aria-label="breadcrumb" className="text-left">
                                    <h2 className="title">Contact us </h2>
                                    <ol className="breadcrumb main-bg">
                                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li className="breadcrumb-item active">Contact us</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 text-right wow fadeInRight"> <img alt="banner" className="img-fluid float-right" src="/images/svg/handshake.svg" /> </div>
                        </div>
                    </div>
                </div>
                <div className="main-content">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="consult-services wow fadeInUp contact-detail text-center">
                                        <div className="circle-effect"> <i aria-hidden="true" className="fa fa-map-marker"></i> </div>
                                        <h4 className="mb-2">Islamabad Office</h4>
                                        <p className="mb-0">SF-09, C-5, Street 97, G-11/3, Islamabad, Pakistan</p>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="consult">
                                        <div className="consult-services wow fadeInUp contact-detail text-center">
                                            <div className="circle-effect"> <i aria-hidden="true" className="fa fa-phone"></i> </div>
                                            <h4 className="mb-2">Phone Support</h4>
                                            <p className="mb-0">Phone: +92-333-5401303
                                            <br />
                                                <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="consult">
                                        <div className="consult-services wow fadeInUp contact-detail text-center">
                                            <div className="circle-effect"> <i aria-hidden="true" className="fa fa-envelope"></i> </div>
                                            <h4 className="mb-2">Email Support</h4>
                                            <p className="mb-0"><a href="mailto:contact@skillsorted.com">contact@skillsorted.com</a>
                                                <br />
                                                <br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="pt-0">
                        <div className="container">
                            <div className="row  align-items-center ">
                                <div className="col-sm-6">
                                    <h2 className="text-left mb-4">Let's talk about for your<br /> business.</h2>

                                    {this.state.formSubmitted === true &&
                                        <div className="response-output ">Thank you for sending the message. We will get in touch with you shortly. </div>
                                    }

                                    {this.state.errorMessage &&
                                        <div className="response-output "> <p className="text-danger">{this.state.errorMessage}</p> </div>
                                    }

                                    {!this.state.formSubmitted &&
                                        <form action="#" method="post" className="">
                                            <div className="contact-form">
                                                <div className="">
                                                    <Textbox
                                                        attributesInput={{
                                                            id: 'Name',
                                                            name: 'Name',
                                                            type: 'text',
                                                            placeholder: 'Name:',
                                                            className: 'form-control text'
                                                        }}
                                                        value={name}
                                                        validate={validate}
                                                        onChange={(name, e) => {
                                                            this.setState({ name });

                                                        }}
                                                        onBlur={(e) => { }}
                                                        validationCallback={res => this.setState({ hasNameError: res, validate: false })}
                                                        validationOption={{
                                                            name: 'Name',
                                                            check: true,
                                                            required: true
                                                        }}
                                                    />
                                                </div>
                                                <div className="">

                                                    <Textbox
                                                        attributesInput={{
                                                            id: 'Email',
                                                            name: 'Email',
                                                            type: 'email',
                                                            placeholder: 'Email:',
                                                            className: 'form-control text'
                                                        }}
                                                        value={email}
                                                        validate={validate}
                                                        onChange={(email, e) => {
                                                            this.setState({ email });

                                                        }}
                                                        onBlur={(e) => { }}
                                                        validationCallback={res => this.setState({ hasEmailError: res, validate: false })}
                                                        validationOption={{
                                                            name: 'Email',
                                                            check: true,
                                                            required: true
                                                        }}
                                                    />



                                                </div>
                                                <div className="">
                                                    <Textbox
                                                        attributesInput={{
                                                            id: 'Subject',
                                                            name: 'Subject',
                                                            type: 'text',
                                                            placeholder: 'Subject:',
                                                            className: 'form-control text'
                                                        }}
                                                        value={subject}
                                                        validate={validate}
                                                        onChange={(subject, e) => {
                                                            this.setState({ subject });

                                                        }}
                                                        onBlur={(e) => { }}
                                                        validationCallback={res => this.setState({ hasSubjectError: res, validate: false })}
                                                        validationOption={{
                                                            name: 'Subject',
                                                            check: true,
                                                            required: true
                                                        }}
                                                    />
                                                </div>
                                                <div className="">

                                                    <Textarea
                                                        attributesInput={{
                                                            id: 'Message',
                                                            name: 'Message',
                                                            type: 'text',
                                                            placeholder: 'Message:',
                                                            className: 'form-control textarea',
                                                            rows: "10"
                                                        }}
                                                        validate={validate}
                                                        value={message}
                                                        onChange={(message, e) => {
                                                            this.setState({ message });
                                                        }}
                                                        onBlur={(e) => { }}
                                                        validationCallback={res => this.setState({ hasMessageError: res, validate: false })}
                                                        validationOption={{
                                                            name: 'Message',
                                                            check: true,
                                                            required: true
                                                        }}
                                                    />

                                                </div>
                                                <div className="d-block w-100 pull-left">
                                                    <ReCAPTCHA sitekey="6LePreUZAAAAAAKwTcLfvduciOKlRnhIBTIyIIx5" onChange={this.onChange} />
                                                    <br />
                                                </div>
                                                <div>
                                                    <button type="button" id="btnSubmit" onClick={this.handleSubmit} className="button d-block w-100">Send</button>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                </div>
                                <div className="mt-5 col-sm-6 offset-lg-1 col-lg-5 offset-md-1 col-md-5">
                                    <div className="wrapper">
                                        {/* <div className="contact-box d-flex">
                                            <i aria-hidden="true" className="ion ion-android-call"></i>
                                            <div className="service-detail">
                                                <h4 className="mb-2">Phone support</h4>
                                                <ul className="list-inline mb-0">
                                                    <li> <a href="tel:+0123456789"> +0123456789</a> </li>
                                                    <li> <a href="tel:+0123456789"> +0123456789</a> </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="contact-box d-flex   ">
                                            <i aria-hidden="true" className="fa fa-envelope-o"></i>
                                            <div className="service-detail">
                                                <h4 className="mb-2">Email us</h4>
                                                <ul className="list-inline mb-0">
                                                    <li><a href="#!"> <span className="__cf_email__" data-cfemail="">[email&#160;protected]</span></a></li>
                                                    <li><a href="#!"> <span className="__cf_email__" data-cfemail="">[email&#160;protected]</span></a></li>
                                                </ul>
                                            </div>
                                        </div> */}
                                        <div className="contact-box d-flex   ">
                                            <i aria-hidden="true" className="ion ion-android-share-alt"></i>
                                            <div className="service-detail">
                                                <h4 className="mb-2">Social media</h4>
                                                <ul>
                                                    {/* <li className="d-inline"><a href="#"><i className="fa fa-facebook-f"></i></a></li>
                                                    <li className="d-inline"><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                    <li className="d-inline"><a href="#"><i className="fa fa-instagram"></i></a></li> */}

                                                    <li className="d-inline"><a href="skype:ali.raza.soft?chat"><i className="fa fa-skype text-primary"></i> </a></li>
                                                    <li className="d-inline"><a href="https://www.facebook.com/skill.sorted.3" target="_blank"><i className="fa fa-facebook-f"></i></a></li>
                                                    <li className="d-inline"><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                                    <li className="d-inline"><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </React.Fragment>
        );
    }
}

export default ContactUs;