import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Home from './components/home';
import Page from './components/page';
import Blog from './components/blog';
import Services from './components/services';
import BlogDetail from './components/blog-detail';
import ContactUs from './components/contact-us';
import Careers from './components/careers';

import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

class App extends React.Component {
    render() {
        return (

            <div className="App">

                <div id="loading">
                    <div id="loading-center">
                        <img src="/images/loader.gif" alt="loader" />
                    </div>
                </div>

                <Header />
                <div className="main-content">
                    <Router>
                        <Route exact path="/">
                            <Redirect to="/home" />
                        </Route>

                        <Route path="/services" component={Services} />

                        <Route path="/careers" component={Careers} />

                        <Route path="/contact-us" component={ContactUs} />

                        <Route path={["/home", "/index", "/default"]} component={Home} />

                        <Route path="/pages/:pageId/:pageName" component={Page} />

                        <Route path={["/blog"]} component={Blog} />

                        <Route path="/blog-detail/:postId/:postTitle" component={BlogDetail} />
                    </Router>
                </div>
                <Footer />
            </div>

        );
    }
}

export default App;
