import React from 'react';
import { apolloClient } from '../apollo/client';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { prepareForURL } from '../utils/helpers'
import { Environment } from "../enviroment";
import { format } from 'date-fns';

const CAREERS_QUERY = gql`
query CareersQuery {
    careers {
        id
        JobTitle
        JobDescription
        Qualifications
       Skills
    }
  }`;

class Careers extends React.Component {
    render() {
        return (
            <ApolloProvider client={apolloClient}>
                <React.Fragment>
                    <div className="text-left iq-breadcrumb-one iq-bg-over black ">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-8 col-md-8 text-left align-self-center">
                                    <nav aria-label="breadcrumb" className="text-left">
                                        <h2 className="title">Careers </h2>
                                        <ol className="breadcrumb main-bg">
                                            <li className="breadcrumb-item"><a href="/home">Home</a></li>
                                            <li className="breadcrumb-item active">Careers</li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 text-right wow fadeInRight">
                                    <img alt="banner" className="img-fluid float-right" src="/images/svg/career.svg" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="iq-accordion career-style">
                                        {/* Starting Job Block */}
                                        <Query query={CAREERS_QUERY} >
                                            {
                                                ({ loading, error, data }) => {

                                                    if (loading) return <div id="loading">
                                                        <div id="loading-center">
                                                            <img src="/images/loader.gif" alt="loader" />
                                                        </div>
                                                    </div>;

                                                    if (error) console.log(error);

                                                    return <React.Fragment> {

                                                        data && data.careers.map(career => {
                                                            return <div key={career.id} className="iq-accordion-block">
                                                                    <div className="active-faq clearfix">
                                                                        <div className="container">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-md-9 col-sm-12 "> <a href="javascript:void(0)" className="accordion-title"><span>{career.JobTitle}</span></a> </div>
                                                                                <div className="col-md-3 col-sm-12 text-center text-lg-right text-md-right"> <a className="button" href="mailto:contact@skillsorted.com">Apply Now</a> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-details">
                                                                        <div className="container">
                                                                            <div className="row mt-4">
                                                                                <div className="col-lg-6 mb-4 mb-lg-0">
                                                                                    {career.JobDescription !== null && career.JobDescription !== undefined &&
                                                                                        <React.Fragment>
                                                                                            <h4 className="mb-3">Job Descriptions:</h4>
                                                                                            <div dangerouslySetInnerHTML={{ __html: career.JobDescription }} />
                                                                                        </React.Fragment>
                                                                                    }

                                                                                    {career.Skills !== null && career.Skills !== undefined &&
                                                                                        <React.Fragment>
                                                                                            <h4 className="mb-3">Required Skills:</h4>
                                                                                            <div dangerouslySetInnerHTML={{ __html: career.Skills }} />
                                                                                        </React.Fragment>
                                                                                    }
                                                                                </div>

                                                                                <div className="col-lg-6">
                                                                                    {career.Qualifications !== null && career.Qualifications !== undefined &&
                                                                                        <React.Fragment>
                                                                                            <h4 className="mb-3">Qualifications:</h4>
                                                                                            <div dangerouslySetInnerHTML={{ __html: career.Qualifications }} />
                                                                                        </React.Fragment>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        })
                                                    }
                                                    </React.Fragment>
                                                }
                                            }
                                        </Query>
                                        {/* Ending Job Block */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section>
                        <div className="container">
                            <Query query={SERVICES_QUERY} >
                                {
                                    ({ loading, error, data }) => {

                                        if (loading) return <div id="loading">
                                            <div id="loading-center">
                                                <img src="/images/loader.gif" alt="loader" />
                                            </div>
                                        </div>;

                                        if (error) console.log(error);

                                        return <div className="row"> {

                                            data && data.services.map(service => {
                                                return <div key={service.id} className="col-lg-4 col-md-6">
                                                    <div className="service-box d-inherit p-4 my-3 wow fadeInUp">
                                                        
                                                        {service.Image !== null && service.Image !== undefined &&
                                                            <img alt={service.Title} src={Environment.STRAPI_URL + service.Image.url} />
                                                        }

                                                        <div className="service-detail">
                                                            <h4 className="mb-2">{service.Title}</h4>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div dangerouslySetInnerHTML={{ __html: service.Description.substring(0, 250) + "... <a href='#'>Read More</a>" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                        </div>
                                    }
                                }
                            </Query>
                        </div>
                    </section> */}
                </React.Fragment>
            </ApolloProvider>);
    }
}

export default Careers;