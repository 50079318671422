import React from 'react';
import { Link } from "react-router-dom";
import { apolloClient } from '../apollo/client';
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

const MENU_QUERY = gql`
query MenuQuery {
    menus {
        id
        Title
        Link
        Page {
            id
            Title
          }
        parent {
            id
            Title
            Link
          }
      }
}`;


const SERVICES_QUERY = gql`
query Services{
    services {
      id
      Title
      Description
       created_at
      updated_at
      Image {
        id
        url
        formats
      }
    }
  }`;

class Footer extends React.Component {
    render() {
        return (
            <ApolloProvider client={apolloClient}>

                <footer className="footer-one iq-bg-dark iq-over-image-bg" id="contact">
                    <div className="container">
                        <div className="footer-top">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6 ">
                                    <p className="footer-logo"><img alt="img" src="/images/ss_logo.png" width="100" /></p>
                                    <p className="footer-logo">A technology consulting company providing excellent quality at an affordable price.</p>
                                    <ul className="info-share d-inline list-inline">
                                        <li className="list-inline-item"><a href="https://www.facebook.com/skill.sorted.3" target="_blank"><i className="fa fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                        <li className="list-inline-item"><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                        <li className="list-inline-item"><a href="skype:ali.raza.soft?chat"><i className="fa fa-skype text-primary"></i></a></li>
                                    </ul>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6 mt-4 mt-lg-0 mt-md-0">
                                    <h4 className="footer-title">Menu</h4>
                                    <div className="menu-footer-menu-container">
                                        <ul className="menu">
                                            <Query query={MENU_QUERY}>
                                                {
                                                    ({ loading, error, data }) => {
                                                        if (loading) return <div className="loader"><div className="loader-inner"><div className="cssload-loader"></div></div></div>;

                                                        if (error) console.log(error);

                                                        return <React.Fragment> {
                                                            data && data.menus.map(menu => {
                                                                let Href = menu.Page ? "/pages/" + menu.Page.id + "/" + menu.Link : "/" + menu.Link;

                                                                return <li className="menu-item" key={menu.id}>
                                                                    <a className="isubmenu" href={Href}> {menu.Title} </a>
                                                                </li>;
                                                            })
                                                        }
                                                        </React.Fragment>;
                                                    }
                                                }
                                            </Query>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mt-lg-0 mt-4">
                                    <h4 className="footer-title iq-rmt-30">Our Services</h4>
                                    <div className="menu-footer-menu-container">
                                        <ul className="menu">
                                            <Query query={SERVICES_QUERY} >
                                                {
                                                    ({ loading, error, data }) => {

                                                        if (loading) return <div id="loading">
                                                            <div id="loading-center">
                                                                <img src="/images/loader.gif" alt="loader" />
                                                            </div>
                                                        </div>;

                                                        if (error) console.log(error);
                                                        return <React.Fragment> {

                                                            data && data.services.map(service => {
                                                                return (<li key={service.id} className="menu-item"> <a href="/Services" aria-current="page"> {service.Title}</a> </li>) ;
                                                            })
                                                        }
                                                        </React.Fragment>
                                                    }
                                                }
                                            </Query>

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 mt-lg-0 mt-4">
                                    <h4 className="footer-title iq-rmt-30">Contact us</h4>
                                    <ul className="iq-contact">
                                        <li> <a href="tel:+923335401303"><i className="fa fa-phone"></i><span>+923335401303</span></a> </li>
                                        <li> <a href="mailto:contact@skillsorted.com"><i className="fa fa-envelope"></i><span> contact@skillsorted.com</span></a> </li>
                                        <li> </li>
                                        <li> <a><i className="fa fa-home"></i><span> SF-09, C-5, Street 97, G-11/3, Islamabad, Pakistan</span></a> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="copyright-footer">
                            <div className="pt-3 pb-3">
                                <div className="row flex-row-reverse justify-content-between">
                                    <div className="col-lg-12 col-md-12 text-lg-center text-md-center text-center"> <span className="copyright">Copyright 2020 <a href="/home">Skill Sorted (PVT) LTD.</a> All Rights Reserved.</span> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </ApolloProvider>
        );
    }

}
export default Footer;